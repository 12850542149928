




































































































import { defineComponent, onMounted, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import { useNotifier } from "@/providers/notifier";
import { createTopOffSession, getPricing } from "@/api/balanceService";

export default defineComponent({
  name: "TopUpBalanceDialog",
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    const hours = ref(1);
    const price = ref(0);
    const loadingPrice = ref(true);

    watchEffect(() => {
      emit("input", _value);
    });

    onMounted(async () => {
      const pricing = await getPricing();
      price.value = pricing;
      loadingPrice.value = false;
    });

    const notify = useNotifier();
    const vm = reactive({
      loading: false,
    });

    async function openPaymentLink(): Promise<void> {
      // between 1 and 150
      if (hours.value < 1 || hours.value > 150) {
        notify({
          title: "Oops!",
          message: "Du kan kun kjøpe mellom 1 og 150 timer av gangen.",
          type: "error",
        });
        return;
      }

      vm.loading = true;
      try {
        const session = await createTopOffSession(hours.value);
        window.open(session.url, "_self")?.focus();
      }
      catch (e) {
        if (e instanceof Error) {
          notify({
            title: "Oops!",
            message: `Noe gikk galt: ${e.message}`,
            type: "error",
          });
        }
        vm.loading = false;
      }
    }

    const incrementHours = () => {
      hours.value = Math.min(hours.value + 1, 150);
    };

    const decrementHours = () => {
      hours.value = Math.max(hours.value - 1, 1);
    };

    return {
      openPaymentLink,
      loadingPrice,
      vm,
      hours,
      _value,
      price,
      incrementHours,
      decrementHours,
    };
  },
});
